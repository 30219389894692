<template>
    <v-card>
        <v-card-title>
        <span class="headline">Maintenance</span>
        </v-card-title>
    
        <v-card-text>
        <v-container>
            <v-row>
                
            </v-row>
        </v-container>
        </v-card-text>
    
        <v-card-actions>
        <v-spacer></v-spacer> 
        <v-btn color="blue darken-1" text @click="$emit('close')">Cancel</v-btn>
        <v-btn color="blue darken-1" text @click="save">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: {
    },
    data: () => ({
        maintenance: {
            maintenanceDate: '',
            mileage: '',
            cost: '',
            location: '',
            description: ''
        }
    }),
    methods: {
        
    }
}
</script>

<style scoped>
.header {
    font-size: 1.5rem;
    font-weight: bold;
}

</style>